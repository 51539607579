.request-container {
  display: flex;
  align-items: flex-start;
  height: 100%;
  flex-flow: column;
}

.request-navigation {
  padding: 20px;
  width: 100%;

  ul {
    padding-left: 0;
    display: flex;
    align-items: centr;
    justify-content: center;

    li {
      padding: 0 20px
    }

    ul {
      padding-left: 1rem;
    }
  }

  .navigation-item {
    cursor: pointer;

    &.active {
      font-weight: bold;
    }
  }
}

.request-form {
  width: 100%;
  padding: 20px;
}

.field-container {
  width: 100%;
  display: flex;
  align-items: center;
}

.field-label {
  width: 20%;
}

.field-container {
  margin-bottom: 10px;

  .cursor-container {
    width: 100%;
    position: relative;
  }
}

.field-input {
  padding: 5px;
  width: 40%;

  &::placeholder {
    font-weight: 200;
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: currentColor;
  animation: blink 1s step-start 0s infinite;
}